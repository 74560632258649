<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">ข้อมูลลูกค้า : {{this.data.totalItems}}</h3>
        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 600px;">
            <input type="text" name="table_search" v-model="search"  v-on:keyup.enter="searchCustomer()" class="form-control float-right" placeholder="Search">
            <div class="input-group-append">
              <button type="submit" class="btn btn-default" @click.prevent="searchCustomer()"><i class="fas fa-search"></i></button>
            </div>
            <button class="btn btn-sm btn-success ml-2" @click.prevent="searchDeail()"  style=""><i class="fas fa-search fa-fw"></i>ค้นหาอย่างละเอียด</button>
            <router-link to="/customercreate" class="btn btn-sm btn-warning ml-2" type="button"><i class="fas fa-plus fa-fw"></i>เพิ่ม ข้อมูลลูกค้า</router-link>
            <button class="btn btn-sm btn-info ml-2" @click.prevent="getResults()"  style=""><i class="fas fa-sync-alt fa-fw"></i></button>
          </div>
        </div>
      </div>
<!--      <div class="card-body">-->
<!--        {{$v}}-->
<!--      </div>-->
      <div class="card-body">
        <div class="row">
          <div class="card-body table-responsive p-0" style="height: 650px;">
            <table class="table table-head-fixed table-hover table-bordered">
              <thead class="thead-light text-center">
              <tr>
<!--                <th scope="col">#</th>-->
<!--                <th scope="col" style="background-color: rgb(197,196,196)">No</th>-->
                <th scope="col" style="background-color: rgb(197,196,196)">Config</th>
                <th v-for="ths in th" :key="ths" style="background-color: rgb(197,196,196)">
                  {{ths}}
                </th>
<!--                <th scope="col" style="background-color: rgb(197,196,196)">Config</th>-->
              </tr>
              </thead>
                <tbody>
                <tr v-for="(datas,index) in data.data" :key="datas.id">
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">
                    <ul style="list-style: none;margin-left: -55px" >
                      <li ><a href="" @click.prevent="showModal(datas)"><i class="fas fa-caret-right fa-fw"></i>ข้อมูลสายตา</a></li>
                      <li ><a href="" @click.prevent="editModal(datas,index)"><i class="fas fa-caret-right fa-fw"></i>แก้ไข</a></li>
                      <li ><a href="" @click.prevent="deleteModal(datas,index)"><i class="fas fa-caret-right fa-fw" ></i>ลบ</a></li>
                    </ul>
                  </td>
<!--                  <th scope="row" style="width: 5px;font-size: 12px" >{{index+1}}</th>-->
                  <td style="width: 7%;font-size: 12px">{{datas.custNo }}</td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">
                    <ul style="list-style: none;margin-left: -35px" >
                      <li >{{datas.custTitle === 'ไม่ระบุ' ? '' : datas.custTitle}}  {{datas.custName}} {{datas.custSurname}}</li>
                      <li >{{datas.custNickname}}</li>
                    </ul>
                  </td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.company.comNameTh }}</td>
                  <td style="font-size: 12px">{{datas.custTel}}</td>
                  <td style="font-size: 12px">
                    <ul style="list-style: none;margin-left: -35px" >
                      <li >{{datas.custMobile}}</li>
                      <li >{{datas.custMobile2}}</li>
                    </ul>
                  </td>
                  <td style="font-size: 12px">{{datas.custTarget }}</td>
                  <td style="font-size: 12px">{{datas.custRelation }}</td>
                  <td style="font-size: 12px">{{datas.custAddress }}</td>
                  <td style="font-size: 12px">{{datas.custSoi }}</td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">{{datas.custRoad }}</td>
                  <td style="font-size: 12px">{{datas.custDistricts }}</td>
                  <td style="font-size: 12px">{{datas.custAmphurs }}</td>
                  <td style="font-size: 12px">{{datas.custProvince }}</td>
                  <td style="font-size: 12px">{{datas.custZipcode }}</td>
                  <td style="max-width:100%;white-space:nowrap;font-size: 12px">
                    <ul style="list-style: none;margin-left: -35px" >
                      <li >{{datas.custRecordData | myDateThs}}</li>
                      <li >{{datas.user.name}}</li>
                    </ul>
                  </td>
                </tr>
                <tr v-show="!this.data"><td colspan="16" class="text-center">ไม่มีรายการ</td></tr>

                </tbody>
              </table>
            </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="float-lg-right">
          <b-pagination
              v-model="page"
              :total-rows="count"
              :per-page="pageSize"
              prev-text="Prev"
              next-text="Next"
              @change="handlePageChange"
          ></b-pagination>
        </div>

      </div>
    </div>
    <!-- /.card -->

    <b-modal size="xl" id="modal-update" ref="modal"
             @ok="update"
             title="แก้ไขผู้ใช้งาน">
      <form ref="form" @submit.prevent="update">
        <div class="row">
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>คำนำหน้า</label>
            </div>
            <Prefix @SetData="SetDataTitle" :remove="remove" :editprefix="editprefix" :class="{ 'is-invalid':$v.form.custTitle.$error }"></Prefix>
            <span v-if="!$v.form.custTitle.required " class="invalid-feedback offset-1">required</span>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>ชื่อ</label>
            </div>
            <input
                v-model.trim="$v.form.custName.$model"
                type="text"
                class="form-control"
                placeholder="ชื่อ"
                :class="{ 'is-invalid':$v.form.custName.$error }"
            />
            <span v-if="!$v.form.custName.required " class="invalid-feedback">required</span>
            <span v-if="!$v.form.custName.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 2 ตัวอักษร.</span>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>นามสกุล</label>
            </div>
            <input
                v-model.trim="$v.form.custSurname.$model"
                type="text"
                class="form-control"
                placeholder="นามสกุล"
                :class="{ 'is-invalid':$v.form.custSurname.$error }"
            />
            <span v-if="!$v.form.custSurname.required " class="invalid-feedback">required</span>
            <span v-if="!$v.form.custSurname.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 2 ตัวอักษร.</span>
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>ชื่อเล่น</label>
            </div>
            <input
                v-model.trim="form.custNickname"
                type="text"
                class="form-control"
                placeholder="ชื่อเล่น"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>สถานที่</label>
            </div>
            <input
                v-model="form.custPlace"
                type="text"
                class="form-control"
                placeholder="สถานที่"
            />
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>เลขที่</label>
            </div>
            <input
                v-model.trim="$v.form.custAddress.$model"
                type="text"
                class="form-control"
                placeholder="เลขที่"
                :class="{ 'is-invalid':$v.form.custAddress.$error }"
            />
            <span v-if="!$v.form.custName.required " class="invalid-feedback">required</span>
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>ตรอก/ซอย</label>
            </div>
            <input
                v-model="form.custSoi"
                type="text"
                class="form-control"
                placeholder="ตรอก/ซอย"
            />
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>ถนน</label>
            </div>
            <input
                v-model.trim="$v.form.custRoad.$model"
                type="text"
                class="form-control"
                placeholder="ถนน"
                :class="{ 'is-invalid':$v.form.custRoad.$error }"
            />
            <span v-if="!$v.form.custRoad.required " class="invalid-feedback">required</span>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-3 col-sm-1">
            <div class="col">
              <label>ตำบล/แขวง</label>
            </div>
            <District @SetData="SetDataDistricts" :remove="remove" :editDistrict="editDistrict" :class="{ 'is-invalid':$v.form.custDistricts.$error }"></District>
            <span v-if="!$v.form.custDistricts.required " class="invalid-feedback offset-1">required</span>
          </div>
          <div class="form-group col-md-3 col-sm-1">
            <div class="col">
              <label>อำเภอ/เขต</label>
            </div>
            <Amphurs @SetData="SetDataAmphurs" :remove="remove" :editAmphurs="editAmphurs" :class="{ 'is-invalid':$v.form.custAmphurs.$error }"></Amphurs>
            <span v-if="!$v.form.custAmphurs.required " class="invalid-feedback offset-1">required</span>
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>จังหวัด</label>
            </div>
            <Province @SetData="SetDataProvince" :remove="remove" :editProvince="editProvince" :class="{ 'is-invalid':$v.form.custProvince.$error }"></Province>
            <span v-if="!$v.form.custProvince.required " class="invalid-feedback offset-1">required</span>
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>รหัสไปรษณีย์</label>
            </div>
            <input
                v-model.trim="$v.form.custZipcode.$model"
                type="text"
                class="form-control"
                placeholder="รหัสไปรษณีย์"
                :class="{ 'is-invalid':$v.form.custZipcode.$error }"
            />
            <span v-if="!$v.form.custZipcode.required " class="invalid-feedback">required</span>
            <span v-if="!$v.form.custZipcode.minLength" class="invalid-feedback">จำนวนตัวอักษรน้อยกว่า 5 ตัวอักษร.</span>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์บ้าน</label>
            </div>
            <input
                v-model.trim="form.custTel"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์บ้าน"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์มือถือ</label>
            </div>
            <input
                v-model.trim="form.custMobile"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์มือถือ"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์มือถือ 2</label>
            </div>
            <input
                v-model.trim="form.custMobile2"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์มือถือ 2"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>ความสัมพันธ์</label>
            </div>
            <input
                v-model.trim="form.custRelation"
                type="text"
                class="form-control"
                placeholder="ความสัมพันธ์"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>กลุ่มเป้าหมาย</label>
            </div>
            <input
                v-model.trim="form.custTarget"
                type="text"
                class="form-control"
                placeholder="กลุ่มเป้าหมาย"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>สาขา</label>
            </div>
            <select v-model.trim="$v.form.companyId.$model" class="form-control" :class="{ 'is-invalid': $v.form.companyId.$error }">
              <option disabled value="">Please select one</option>
              <option v-for="c in company" :key="c.companyId" :value="c.companyId">
                {{c.comNameTh}}
              </option>
            </select>
            <span v-if="!$v.form.companyId.required " class="invalid-feedback">required</span>
          </div>
        </div>
        <div class="form-group row text-center">
              <label for="inputEmail3" class="col-md-2 col-form-label"> ต้องการข่าวสาร</label>
              <div class="col-md-4" style="padding-left: 40px">
                <select v-model="form.custNews" class="form-control" id="inputEmail3">
                  <option  :value="'True'">ต้องการข่าวสาร</option>
                  <option  :value="'False'">ไม่ต้องการข่าวสาร</option>
                </select>
            </div>
        </div>
      </form>
    </b-modal>
    <b-modal size="xl" id="modal-search" ref="modal"
             @ok="searchAll"
             title="ค้นหาอย่างละเอียด">
      <form ref="form" @submit.prevent="searchAll">
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>ชื่อ</label>
            </div>
            <input
                v-model="formSearch.custName"
                type="text"
                class="form-control"
                placeholder="ชื่อ"
            />
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>นามสกุล</label>
            </div>
            <input
                v-model="formSearch.custSurname"
                type="text"
                class="form-control"
                placeholder="นามสกุล"
            />
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>ชื่อเล่น</label>
            </div>
            <input
                v-model="formSearch.custNickname"
                type="text"
                class="form-control"
                placeholder="ชื่อเล่น"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>สถานที่</label>
            </div>
            <input
                v-model="formSearch.custPlace"
                type="text"
                class="form-control"
                placeholder="สถานที่"
            />
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>เลขที่</label>
            </div>
            <input
                v-model="formSearch.custAddress"
                type="text"
                class="form-control"
                placeholder="เลขที่"
            />
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>ตรอก/ซอย</label>
            </div>
            <input
                v-model="formSearch.custSoi"
                type="text"
                class="form-control"
                placeholder="ตรอก/ซอย"
            />
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>ถนน</label>
            </div>
            <input
                v-model="formSearch.custRoad"
                type="text"
                class="form-control"
                placeholder="ถนน"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-3 col-sm-1">
            <div class="col">
              <label>ตำบล/แขวง</label>
            </div>
            <input
                v-model="formSearch.custDistricts"
                type="text"
                class="form-control"
                placeholder="ตำบล/แขวง"
            />
          </div>
          <div class="form-group col-md-3 col-sm-1">
            <div class="col">
              <label>อำเภอ/เขต</label>
            </div>
            <input
                v-model="formSearch.custAmphurs"
                type="text"
                class="form-control"
                placeholder="อำเภอ/เขต"
            />
          </div>
          <div class="form-group col-md-4 col-sm-1">
            <div class="col">
              <label>จังหวัด</label>
            </div>
            <input
                v-model="formSearch.custProvince"
                type="text"
                class="form-control"
                placeholder="จังหวัด"
            />
          </div>
          <div class="form-group col-md-2 col-sm-1">
            <div class="col">
              <label>รหัสไปรษณีย์</label>
            </div>
            <input
                v-model="formSearch.custZipcode"
                type="text"
                class="form-control"
                placeholder="รหัสไปรษณีย์"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์บ้าน</label>
            </div>
            <input
                v-model="formSearch.custTel"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์บ้าน"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์มือถือ</label>
            </div>
            <input
                v-model="formSearch.custMobile"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์มือถือ"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>โทรศัพท์มือถือ 2</label>
            </div>
            <input
                v-model="formSearch.custMobile2"
                type="text"
                class="form-control"
                placeholder="โทรศัพท์มือถือ 2"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>ความสัมพันธ์</label>
            </div>
            <input
                v-model="formSearch.custRelation"
                type="text"
                class="form-control"
                placeholder="ความสัมพันธ์"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>กลุ่มเป้าหมาย</label>
            </div>
            <input
                v-model="formSearch.custTarget"
                type="text"
                class="form-control"
                placeholder="กลุ่มเป้าหมาย"
            />
          </div>
          <div class="form-group col-md-6 col-sm-1">
            <div class="col">
              <label>สาขา</label>
            </div>
            <select v-model ="formSearch.companyId" class="form-control">
              <option :value= "0">ทั้งหมด</option>
              <option v-for="c in company" :key="c.companyId" :value="c.companyId">
                {{c.comNameTh}}
              </option>
            </select>
          </div>
        </div>
      </form>
    </b-modal>
  </section>
</template>

<script>
import axios from 'axios';
import { Form } from 'vform';
import { required, minLength  } from "vuelidate/lib/validators";
import {mapActions} from "vuex";
import Province from "@/components/Province";
import Amphurs from "@/components/Amphurs";
import District from "@/components/District";
import Prefix from "@/components/Prefix";
import router from "@/router";
export default {
  name: 'customers',
  components: {Province, Amphurs, District, Prefix},
  data(){
    return{
      data:'',
      th:['รหัสลูกค้า','ชื่อ-นามสกุล','สาขา','เบอร์โทร','มือถือ','ความสัมพันธ์','กลุ่ม','เลขที่','ตรอก/ซอย','ถนน','ตำบล','อำเภอ','จังหวัด','รหัส','วันที่สร้าง'],
      page: 1,
      count: 0,
      pageSize: 0,
      Index: '',
      nameState: null,
      company: '',
      search: '',
      remove: [],
      editprefix: '',
      editDistrict: '',
      editAmphurs: '',
      editProvince: '',


      form : new Form ({
        custId:'',
        companyId: 0 ,
        custNo : '',
        custRecordData : '',
        custNews : '',
        custTitle : '',
        custName : '',
        custSurname : '',
        custNickname : '',
        custPlace : '',
        custAddress : '',
        custSoi : '',
        custRoad : '',
        custDistricts : '',
        custAmphurs : '',
        custProvince : '',
        custZipcode : '',
        custTel : '',
        custMobile : '',
        custMobile2 : '',
        custRelation : '',
        custBirthday : '',
        custTarget : '',
      }),

      formSearch : new Form ({
        custId:'',
        companyId:'',
        custNo : '',
        custRecordData : '',
        custNews : '',
        custTitle : '',
        custName : '',
        custSurname : '',
        custNickname : '',
        custPlace : '',
        custAddress : '',
        custSoi : '',
        custRoad : '',
        custDistricts : '',
        custAmphurs : '',
        custProvince : '',
        custZipcode : '',
        custTel : '',
        custMobile : '',
        custMobile2 : '',
        custRelation : '',
        custBirthday : '',
        custTarget : '',
      })
    }
  },

  validations: {
    form : {
      companyId: {
        required,
      },
      custTitle: {
        required,
      },
      custName: {
        required,
        minLength: minLength(2)
      },
      custSurname: {
        required,
        minLength: minLength(2)
      },
      custAddress: {
        required,
      },
      custRoad: {
        required,
      },
      custDistricts: {
        required,
      },
      custAmphurs: {
        required,
      },
      custProvince: {
        required,
      },
      custZipcode: {
        required,
        minLength: minLength(5)
      },
    }

  },
  mounted() {
    this.getResults()
  },
  methods:{
    ...mapActions(['logout']),
    async getResults(){
       await axios.get('api/customer/customerIndex'+'?page=' + this.page )
          .then(response => {
            if (response.data.success === true)
            this.data = response.data
            this.count = response.data.totalItems;
            this.pageSize = response.data.limit;
            this.search = ''
          }).catch((error)=>{
             if (error.response.status === 500) {
               this.$swal('something wrong', error.response.data.msg, 'error');
             }
           });
    },
    handlePageChange(value) {
      if (!this.search){
        this.page = value;
        this.getResults();
      }else{
        this.page = value;
        this.searchCustomer();
      }

    },

    clearSelect(){
      this.editprefix = ''
      this.editDistrict = ''
      this.editAmphurs = ''
      this.editProvince = ''
    },

    async searchDeail(){
      if (!this.company){
        await axios.get('api/company/getCompany')
            .then(response => {
              if (response.status === 200)
                this.company = response.data
            });
      }
      this.formSearch.companyId = 0
      this.$bvModal.show('modal-search')
    },


    async searchAll(bvModalEvt){
      // if (this.formSearch.custName || this.formSearch.custSurname || this.formSearch.custTarget
      //     || this.formSearch.custTel || this.formSearch.custMobile
      // ){
        bvModalEvt.preventDefault()
        await this.formSearch.post('api/customer/customerSearchAll'+'?page=' + this.page)
            .then( async response => {
              if (response.data.success === true ){
                this.data = response.data
                this.count = response.data.totalItems;
                this.pageSize = response.data.limit;
                this.toast('Your work has been search')
                this.$bvModal.hide('modal-search')
              }
            }).catch((error) => {
              if (error.response.status === 500) {
                this.$swal('something wrong', error.response.data.msg, 'error');
              }
            });

      // }else {
      //   this.$swal('something wrong', 'กรุณาใส่ข้อมูล', 'error');
      // }

    },

    async editModal(data,index){
      // console.log(data)
      // this.form.reset()
      // this.form.clear()
      if (!this.company){
        await axios.get('api/company/getCompany')
            .then(response => {
              if (response.status === 200)
                this.company = response.data
            });
      }
      this.$v.$reset()
      this.Index = index
      await this.form.fill(data)
      await this.clearSelect()
      this.$bvModal.show('modal-update')
      this.$nextTick(() => {
        this.editprefix = data.custTitle
        this.editDistrict = data.custDistricts
        this.editAmphurs = data.custAmphurs
        this.editProvince = data.custProvince
      })

    },
    async update(bvModalEvt){
      bvModalEvt.preventDefault()
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) return;
      await this.form.put('api/customer/customerUpdate/'+this.form.custId)
          .then( async response => {
            if (response.data.success === true ){
              await this.data.data.splice(this.Index,1,response.data.data)
              this.$bvModal.hide('modal-update')
              this.toast('Your work has been Update')
            }
          }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
            }
          });
    },


    toast(msg){
      this.$swal({
        position: 'top-end',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 1500
      })
    },

    async searchCustomer(){
      if (this.search){
        await axios.post('api/customer/customerFind'+'?page=' + this.page,{ search : this.search})
          .then( async response => {
              if (response.data.success === true ){
                this.data = response.data
                this.count = response.data.totalItems;
                this.pageSize = response.data.limit;
                this.toast('Your work has been search')
              }
            }).catch((error) => {
            if (error.response.status === 500) {
              this.$swal('something wrong', error.response.data.msg, 'error');
              }
            });

      }else {
        this.$swal('something wrong', 'กรุณาใส่ข้อมูล', 'error');
      }
    },

    async deleteModal(data,index){
      this.$swal({
        title: 'Are you sure?',
        text: "ท่านต้องการลบข้อมูลใช่หรือไม่!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then( async (result) => {
        if (result.value) {
          await axios.delete('api/customer/customerDelete/'+data.custId)
              .then(async(res) => {
                if (!res.data.success === true){
                  this.$swal({
                    type: 'error',
                    title: 'ข้อมูลไม่ถูกต้อง',
                    text: 'ข้อมูลไม่ถูกต้อง',
                    showConfirmButton: true,
                    timer: 5000
                  })
                }else {
                  await this.data.data.splice(index,1)
                  this.$swal(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                      )
                  }
                })
        }
      }).catch((err) => {
        this.$swal("Failed!", "There was something wronge", "warning");
        console.log(err)
      })
    },


    async showModal(data){
      await this.$store.commit('set_custdata',data)
      router.push('/customerdetail');

      // this.$store.commit('clear_custdata')
      // console.log(data)
    },

    SetDataTitle(data){
      this.form.custTitle = data
    },

    SetDataDistricts(data){
      this.form.custDistricts = data
    },

    SetDataAmphurs(data){
      this.form.custAmphurs = data
    },

    SetDataProvince(data){
      this.form.custProvince = data
    },

    printResults(){
      const options = {
        responseType: 'arraybuffer',
      };

       axios.get('api/customer/customerReport',options)
          .then((res) => {
            // console.log(res.data);
            let blob = new Blob([res.data], {type: "application/pdf"});
            let win = window.open('', '_blank');
            let URL = window.URL || window.webkitURL;
            let dataUrl = URL.createObjectURL(blob);
            win.location = dataUrl;
          })
    },

  }

}
</script>
